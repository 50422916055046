import Box from '@mui/material/Box';
import DeviceLogsTable from './DeviceLogsTable';

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      width: '100%',
      textAlign: 'center',
    })}>
    {children}
  </Box>
);

const DeviceLogsSection = () => {
  return (
    <Container>
      <DeviceLogsTable />
    </Container>
  );
};

export default DeviceLogsSection;
