export const isDateString = value => {
  if (value instanceof Date) return true;
  if (!value || typeof value !== 'string') return false;

  const versionPattern = /^\d+(\.\d+)+$/;
  if (versionPattern.test(value)) return false;

  const containsVersion = value.split(/\s+/).some(part => versionPattern.test(part));
  if (containsVersion) return false;

  if (!isNaN(Number(value.trim()))) return false;

  const nonDatePattern = /[a-zA-Z]+\s\d{4}$/;
  if (nonDatePattern.test(value)) return false;

  const date = new Date(value);
  return !isNaN(date.getTime()) && date.toString() !== 'Invalid Date';
};

export const getDateSelectedDayAgo = day => {
  const pastDay = new Date().getDate() - day + 1;
  const pastDateMiliseconds = new Date().setDate(pastDay);

  return new Date(pastDateMiliseconds);
};
