import { useCallback } from 'react';
import useInfiniteFetch from '../../../../queries/useInfiniteFetch';
import useSubscribeStorage from '../../../../queries/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import { DEVICE_UNMATCHED_FILES_QUERY_KEY } from 'src/constants/query';
import DeviceService from 'src/services/device.service';
import { EXPANDED_ROWS_LIMIT } from 'src/constants/table';

const useFetchUnmatchedFiles = ({ deviceId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      DeviceService.getDeviceUnmatchedFilesWdacResults({
        limit,
        page,
        deviceId,
        customerEnvId,
      }),
    [deviceId, customerEnvId],
  );
  const response = useInfiniteFetch({
    queryKey: [DEVICE_UNMATCHED_FILES_QUERY_KEY, customerEnvId, deviceId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'results',
  });

  return {
    ...response,
  };
};

export default useFetchUnmatchedFiles;
