import CustomCell from 'src/components/Table/CustomCell';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

export const defaultAppresultFilters = {
  FilterAppResult: [
    [
      {
        name: 'Name',
        type: 'date',
        value: '',
        label: 'Name',
      },
    ],
  ],
};

export const topHeadingDevice = [
  {
    title: '',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Launch Attempts',
    colSpan: 4,
    borderRight: true,
  },
  {
    title: '',
    colSpan: 1,
  },
];

export const secondHeadingsDevice = [
  {
    title: 'Name',
    name: 'arpDisplayName',
    hasSorting: true,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_name',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Version',
    name: 'arpDisplayVersion',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_display_version',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Publisher',
    name: 'arpPublisher',
    borderRight: true,
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'arp_publisher',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: '24 Hours',
    name: 'estimatedLaunchAttemptsLast24Hours',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last24hours',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: '30 Days',
    name: 'estimatedLaunchAttemptsLast30Days',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'estimated_launch_attempts_last30days',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'All',
    name: 'estimatedLaunchAttempts',
    hasSorting: false,
  },
  {
    title: 'Last launch time',
    name: 'lastLaunchTime',
    borderRight: true,
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'last_launch_time',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Matched apps in current environment',
    name: 'managedApplicationMatchCount',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterAppResult',
    sortingCellField: 'managed_application_match_count',
    sortFieldName: 'OrderWdacQuery',
  },
];

export const columnsOrderDevice = [
  { name: 'arpDisplayName', styles: { maxWidth: '200px' } },
  { name: 'arpDisplayVersion' },
  { name: 'arpPublisher', styles: { maxWidth: '200px' } },
  { name: 'estimatedLaunchAttemptsLast24Hours', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast30Days', isNumber: true },
  { name: 'estimatedLaunchAttempts', isNumber: true },
  { name: 'lastLaunchTime' },
  { name: 'managedApplicationMatchCount', isNumber: true },
];
