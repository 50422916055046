export const topHeading = [
  {
    title: 'ARP',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Blocked/Audited Devices',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Launch Attempts',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: '',
    colSpan: 1,
  },
];

export const topHeadingDevice = [
  {
    title: '',
    colSpan: 3,
    borderRight: true,
  },
  {
    title: 'Launch Attempts',
    colSpan: 4,
    borderRight: true,
  },
  {
    title: '',
    colSpan: 1,
  },
];

export const secondHeadings = [
  {
    title: 'Publisher',
    name: 'arpPublisher',
  },
  {
    title: 'Name',
    name: 'arpDisplayName',
  },
  {
    title: 'Version',
    name: 'arpDisplayVersion',
    borderRight: true,
  },
  {
    title: 'All',
    name: 'devicesBlockedOrAudited',
  },
  {
    title: '30 Days',
    name: 'devicesLast30Days',
  },
  {
    title: '24 Hours',
    name: 'devicesLast24Hours',
    borderRight: true,
  },
  {
    title: 'All',
    name: 'estimatedLaunchAttempts',
  },
  {
    title: '30 Days',
    name: '',
  },
  {
    title: '24 Hours',
    name: 'estimatedLaunchAttemptsLast24Hours',
    borderRight: true,
  },
  {
    title: 'Managed App Match Count',
    name: 'managedApplicationMatchCount',
  },
];

export const secondHeadingsDevice = [
  {
    title: 'Name',
    name: 'arpDisplayName',
  },
  {
    title: 'Version',
    name: 'arpDisplayVersion',
  },
  {
    title: 'Publisher',
    name: 'arpPublisher',
    borderRight: true,
  },
  {
    title: '24 Hours',
    name: 'estimatedLaunchAttemptsLast24Hours',
  },
  {
    title: '30 Days',
    name: 'estimatedLaunchAttemptsLast30Days',
  },
  {
    title: 'All',
    name: 'estimatedLaunchAttempts',
  },
  {
    title: 'Last launch time',
    name: 'lastLaunchTime',
    borderRight: true,
  },
  {
    title: 'Matched apps in current environment',
    name: 'managedApplicationMatchCount',
  },
];

export const columnsOrder = [
  { name: 'arpPublisher', styles: { maxWidth: '200px' } },
  { name: 'arpDisplayName', styles: { maxWidth: '200px' } },
  'arpDisplayVersion',
  { name: 'devicesBlockedOrAudited', isNumber: true },
  { name: 'devicesLast30Days', isNumber: true },
  { name: 'devicesLast24Hours', isNumber: true },
  { name: 'estimatedLaunchAttempts', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast30Days', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast24Hours', isNumber: true },
  { name: 'managedApplicationMatchCount', isNumber: true },
];

export const columnsOrderDevice = [
  { name: 'arpDisplayName', styles: { maxWidth: '200px' } },
  { name: 'arpDisplayVersion' },
  { name: 'arpPublisher', styles: { maxWidth: '200px' } },
  { name: 'estimatedLaunchAttemptsLast24Hours', isNumber: true },
  { name: 'estimatedLaunchAttemptsLast30Days', isNumber: true },
  { name: 'estimatedLaunchAttempts', isNumber: true },
  { name: 'lastLaunchTime' },
  { name: 'managedApplicationMatchCount', isNumber: true },
];

export const VISIBLE_DEVICES_COUNT = 5;
