import { pagesText } from '../../constants/pagesText';
import {
  DEVICE_TAGS_QUERY_KEY,
  LOG_TYPES_QUERY_KEY,
  OS_SKUS_QUERY_KEY,
  OS_TYPES_QUERY_KEY,
  OS_VERSIONS_QUERY_KEY,
} from '../../constants/query';

const appsFilterValues = {
  contain: 'contain',
  notContain: 'notContain',
  executed: 'executed',
  installed: 'installed',
  yes: true,
  no: false,
  Start: 'Start',
  End: 'End',
};

const defaultFilterConditionLimit = 5;

export const blockedByDynamicCodeSecurityOptions = [
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.executed,
        value: appsFilterValues.executed,
      },
      {
        label: pagesText.filters.filter.installed,
        value: appsFilterValues.installed,
      },
    ],
    value: appsFilterValues.executed,
  },
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.yes,
        value: appsFilterValues.yes,
      },
      {
        label: pagesText.filters.filter.no,
        value: appsFilterValues.no,
      },
    ],
    value: '',
  },
];

export const sortFieldsNameMap = {
  environmentApplicationName: 'configMgrAppName',
  publisher: 'configMgrAppManufacturer',
  name: 'FilterDevice',
  createdAt: 'DateFilter',
};

export const filtersConfigs = {
  softwareCenterName: {
    filtersCount: defaultFilterConditionLimit,
    canAddFilterCondition: true,
  },
  configMgrAppManufacturer: {
    filtersCount: defaultFilterConditionLimit,
    canAddFilterCondition: true,
  },
  configMgrAppName: {
    filtersCount: defaultFilterConditionLimit,
    canAddFilterCondition: true,
  },
  blockedByDynamicCodeSecurity: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
    canAddFilterCondition: true,
  },
  trustedByISG: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
    canAddFilterCondition: true,
  },
  FilterDevice: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  wdacDeviceStatus: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  healthStatus: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  status: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  osVersion: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  osSku: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  osType: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  logTypes: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },

  logCollectorVersionSelect: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  TagName: {
    filtersCount: 1,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  DateFilter: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
  lastWdacHeartbeatDateFilter: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
    canAddFilterCondition: false,
  },
};

export const defaultAppsFilters = {
  softwareCenterName: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: appsFilterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: appsFilterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  configMgrAppManufacturer: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: appsFilterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: appsFilterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  configMgrAppName: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: appsFilterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: appsFilterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  blockedByDynamicCodeSecurity: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.executed,
            value: appsFilterValues.executed,
          },
          {
            label: pagesText.filters.filter.installed,
            value: appsFilterValues.installed,
          },
        ],
        value: '',
      },
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.yes,
            value: appsFilterValues.yes,
          },
          {
            label: pagesText.filters.filter.no,
            value: appsFilterValues.no,
          },
        ],
        value: '',
      },
    ],
  ],
  trustedByISG: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.executed,
            value: appsFilterValues.executed,
          },
          {
            label: pagesText.filters.filter.installed,
            value: appsFilterValues.installed,
          },
        ],
        value: '',
      },
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.yes,
            value: appsFilterValues.yes,
          },
          {
            label: pagesText.filters.filter.no,
            value: appsFilterValues.no,
          },
        ],
        value: '',
      },
    ],
  ],
  DateFilter: [
    [
      {
        name: appsFilterValues.Start,
        type: 'date',
        value: '',
        label: 'From',
      },
    ],
    [
      {
        name: appsFilterValues.End,
        type: 'date',
        value: '',
        label: 'To',
      },
    ],
  ],
};

const devicesFilterValues = {
  lastContactDateGreaterThan: 'LastContactDateGreaterThan',
  lastContactDateLessThan: 'LastContactDateLessThan',
  approved: 'approved',
  revoked: 'revoked',
  unknown: 'unknown',
  enforced: 'enforced',
  audit: 'audit',
  reported: 'reported',
  notReported: 'notReported',
  start: 'Start',
  end: 'End',
};

const devicelogTypesOptions = [
  {
    label: 'Policy load events (ID 3099)',
    value: 'CodeIntegrityOperational3099',
  },
  {
    label: 'Win32 audit events (ID 3076)',
    value: 'CodeIntegrityOperational3076',
  },
  {
    label: 'Win32 enforced events (ID 3077)',
    value: 'CodeIntegrityOperational3077',
  },
];

export const defaultDeviceLogsFilters = {
  DateFilter: [
    [
      {
        name: 'Start',
        type: 'date',
        value: '',
        label: 'From',
        source: 'LogFilesFilter',
      },
    ],
    [
      {
        name: 'End',
        type: 'date',
        value: '',
        label: 'To',
        source: 'LogFilesFilter',
      },
    ],
  ],

  logTypes: [
    [
      {
        type: 'menu',
        name: 'logTypes',
        value: '',
        options: devicelogTypesOptions,
      },
    ],
  ],
};

export const defaultDeviceFilters = {
  FilterDevice: [
    [
      {
        name: devicesFilterValues.lastContactDateGreaterThan,
        type: 'date',
        value: '',
        label: 'From',
      },
    ],
    [
      {
        name: devicesFilterValues.lastContactDateLessThan,
        type: 'date',
        value: '',
        label: 'To',
      },
    ],
  ],
  wdacDeviceStatus: [
    [
      {
        name: 'WdacStatus',
        type: 'menu',
        options: [
          {
            label: pagesText.devices.filters.wdacDeviceStatus.unknown,
            value: devicesFilterValues.unknown,
          },
          {
            label: pagesText.devices.filters.wdacDeviceStatus.enforced,
            value: devicesFilterValues.enforced,
          },
          {
            label: pagesText.devices.filters.wdacDeviceStatus.audit,
            value: devicesFilterValues.audit,
          },
        ],
        value: '',
      },
    ],
  ],
  status: [
    [
      {
        name: 'Status',
        type: 'menu',
        options: [
          {
            label: pagesText.devices.filters.status.approved,
            value: devicesFilterValues.approved,
          },
          {
            label: pagesText.devices.filters.status.revoked,
            value: devicesFilterValues.revoked,
          },
          {
            label: pagesText.devices.filters.status.unknown,
            value: devicesFilterValues.unknown,
          },
        ],
        value: '',
      },
    ],
  ],
  healthStatus: [
    [
      {
        name: 'healthStatus',
        type: 'menu',
        options: [
          {
            label: pagesText.devices.filters.healthStatus.reported,
            value: devicesFilterValues.reported,
          },
          {
            label: pagesText.devices.filters.healthStatus.notReported,
            value: devicesFilterValues.notReported,
          },
        ],
        value: '',
      },
    ],
  ],
  osVersion: [
    [
      {
        type: 'autocomplete',
        name: 'osVersion',
        value: '',
        itemName: 'properties',
        queryKey: OS_VERSIONS_QUERY_KEY,
      },
    ],
  ],
  osSku: [
    [
      {
        type: 'autocomplete',
        name: 'osSku',
        value: '',
        itemName: 'properties',
        queryKey: OS_SKUS_QUERY_KEY,
      },
    ],
  ],
  osType: [
    [
      {
        type: 'autocomplete',
        name: 'osType',
        value: '',
        itemName: 'properties',
        queryKey: OS_TYPES_QUERY_KEY,
      },
    ],
  ],
  logCollectorVersionSelect: [
    [
      {
        type: 'autocomplete',
        name: 'logCollectorVersionSelect',
        value: '',
        itemName: 'versions',
        queryKey: LOG_TYPES_QUERY_KEY,
      },
    ],
  ],
  lastWdacHeartbeatDateFilter: [
    [
      {
        name: devicesFilterValues.start,
        type: 'date',
        value: '',
        label: 'From',
        source: 'FilterDevice',
      },
    ],
    [
      {
        name: devicesFilterValues.end,
        type: 'date',
        value: '',
        label: 'To',
        source: 'FilterDevice',
      },
    ],
  ],
  TagName: [
    [
      {
        type: 'autocomplete',
        name: 'TagIds',
        value: '',
        queryKey: DEVICE_TAGS_QUERY_KEY,
        itemName: 'tags',
      },
    ],
  ],
};

export const sidebarList = [
  {
    title: 'software center name',
    name: 'softwareCenterName',
  },
  {
    title: 'publisher',
    name: 'configMgrAppManufacturer',
  },
  {
    title: 'Config Manager Name',
    name: 'configMgrAppName',
  },
  {
    title: pagesText.filters.blockedByDynamicCodeSecurity,
    name: 'blockedByDynamicCodeSecurity',
  },
  {
    title: pagesText.filters.trustedByISG,
    name: 'trustedByISG',
  },
  {
    title: pagesText.filters.dateFilter,
    name: 'DateFilter',
  },
];
