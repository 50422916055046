import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useDevice from '../../../../queries/deviceDetails/useDevice';
import { Loader } from '../../../../components';
import { formatDate } from '../../../../utils/formatTime';
import { deviceStatuses } from '../../constants'; // Make sure to have options for tags and statuses
import useMutateDeviceDetailsStatus from 'src/queries/devices/useMutateDeviceDetailsStatus';
import { Chip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';
import useDialog from 'src/queries/useDialog';
import DeviceTagModal from '../../DeviceTagModal';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import TagService from 'src/services/tag.service';
import { DEVICE_QUERY_KEY } from 'src/constants/query';
import { pagesText } from 'src/constants/pagesText';
import useRemoveListTag from 'src/queries/devices/useRemoveListTag';
import TagChangeModal from './TagChangeModal';
import useSubscribeStorage from 'src/queries/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const StyledChip = styled(Chip)({
  border: '0.5px solid #fff',
  backgroundColor: '#3f3f3f',
  '&:hover': {
    backgroundColor: '#3f3f3f',
  },
  '& .MuiChip-deleteIcon': {
    fill: '#fff',
    color: '#fff',

    '&:hover': {
      fill: '#fff',
      color: '#fff',
    },
  },
});

const fieldsToRender = [
  { key: 'name', label: 'Name (Computer Name)' },
  { key: 'uniqueExternalId', label: 'Unique_External_id' },
  { key: 'status', label: 'Status' },
  { key: 'tag', label: 'Device Tags' },
];

const osDetails = [
  { key: 'osVersion', label: 'OSVersion' },
  { key: 'osSku', label: 'SKU' },
  { key: 'osType', label: 'OSType' },
];

const additionalFields = [
  { key: 'logCollectorVersion', label: 'LogCollector version' },
  { key: 'createdAt', label: 'Created Date', format: formatDate },
  { key: 'updatedAt', label: 'Updated Date', format: formatDate },
  { key: 'lastContactDate', label: 'Last Contact Date', format: formatDate },
  {
    key: 'lastWdacHeartbeatDate',
    label: 'WDAC Last Heartbeat Date',
    format: value => (value ? formatDate(value) : 'No heartbeat was ever received'),
  },
  {
    key: 'wdacStatus',
    label: 'WDAC Status',
    format: value => (value ? value : 'None'),
  },
];

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      width: '100%',
    })}>
    {children}
  </Box>
);

const DeviceGeneralSection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const { deviceId } = useParams();
  const { isLoading, data: device } = useDevice({ deviceId });
  const { mutate } = useMutateDeviceDetailsStatus({});
  const [status, setStatus] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState(null);

  const deviceTagMutation = useMutation(TagService.editDeviceTags);

  const removeTagMutation = useRemoveListTag();

  const {
    open: isChangeDeviceTagDialogOpen,
    onOpen: onChangeDeviceTagDialogOpen,
    onClose: onChangeDeviceTagDialogClose,
  } = useDialog();

  const {
    open: isEditTagDialogOpen,
    onOpen: onEditTagDialogOpen,
    onClose: onEditTagDialogClose,
  } = useDialog();

  useEffect(() => {
    if (device) {
      setStatus(device.device?.status || '');
      setTags(device.tags || []);
    }
  }, [device]);

  const updateStatusMutation = useCallback(
    newStatus => {
      mutate({ deviceId: deviceId, status: newStatus });
    },
    [deviceId, mutate],
  );

  const handleStatusChange = useCallback(
    event => {
      const newStatus = event.target.value;
      setStatus(newStatus);
      updateStatusMutation(newStatus);
    },
    [updateStatusMutation],
  );

  const onUpdateDeviceTag = useCallback(
    ({ tagNames, tagIds }) => {
      deviceTagMutation.mutate(
        {
          tagNames: tagNames,
          deviceId: deviceId,
          customerEnvId: customerEnvId,
          tagIds: tagIds,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([DEVICE_QUERY_KEY]);

            onEditTagDialogClose();
            onChangeDeviceTagDialogClose();

            enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceTag, {
              variant: 'success',
              autoHideDuration: 3000,
            });
          },
          onError: () => {
            enqueueSnackbar(pagesText.somethingWentWrong, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          },
        },
      );
    },
    [
      enqueueSnackbar,
      queryClient,
      deviceTagMutation,
      onEditTagDialogClose,
      onChangeDeviceTagDialogClose,
      deviceId,
      customerEnvId,
    ],
  );

  const onDeleteTag = useCallback(
    ({ tagId, tagSearch }) => {
      removeTagMutation.mutate({
        tagId,
        deviceId: deviceId,
        search: tagSearch,
      });
    },
    [removeTagMutation, deviceId],
  );

  const onClickTagChip = useCallback(
    tagId => {
      setSelectedTagId(tagId);
      onChangeDeviceTagDialogOpen();
    },
    [onChangeDeviceTagDialogOpen],
  );

  const onChangeDeviceTags = useCallback(async () => {
    if (selectedTagId) {
      const newTags = tags.filter(tag => tag.id !== selectedTagId).map(tag => tag.id);

      await onUpdateDeviceTag({
        tagIds: [...newTags],
        tagNames: [],
      });

      setSelectedTagId(null);
    }
  }, [onUpdateDeviceTag, tags, selectedTagId]);

  if (isLoading) {
    return (
      <Box mt={'80px'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Table>
          <TableBody>
            {fieldsToRender.map(({ key, label }) => (
              <TableRow key={key}>
                <TableCell>{label}:</TableCell>
                <TableCell>
                  {key === 'status' ? (
                    <Select
                      value={status.toLowerCase()}
                      onChange={handleStatusChange}
                      size="small"
                      sx={{ color: '#fff', backgroundColor: '#333' }}>
                      {deviceStatuses.map(statusOption => (
                        <MenuItem key={statusOption.value} value={statusOption.value}>
                          {statusOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : key === 'tag' ? (
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                      {tags.length ? (
                        <Stack direction="row" alignItems={'center'} gap={1} flexWrap={'wrap'}>
                          {tags.map(tag => (
                            <StyledChip
                              key={tag.id}
                              label={tag.name}
                              onDelete={() => {
                                onClickTagChip(tag.id);
                              }}
                              deleteIcon={<Iconify icon="mdi:close" />}
                            />
                          ))}
                        </Stack>
                      ) : (
                        'no data'
                      )}
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          onEditTagDialogOpen();
                        }}>
                        <Iconify icon="ic:baseline-edit" color="#a2a2a2" width={24} height={24} />
                      </IconButton>
                    </Stack>
                  ) : (
                    device?.device[key] || 'no data'
                  )}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={2}>OS Details</TableCell>
            </TableRow>
            {osDetails.map(({ key, label }) => (
              <TableRow key={key}>
                <TableCell sx={{ pl: 5 }}> - {label}:</TableCell>
                <TableCell>{device?.device[key] || 'no data'}</TableCell>
              </TableRow>
            ))}

            {additionalFields.map(({ key, label, format }) => (
              <TableRow key={key}>
                <TableCell>{label}:</TableCell>
                <TableCell>
                  {format ? format(device?.device[key]) : device?.device[key] || 'no data'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <TagChangeModal
        open={isChangeDeviceTagDialogOpen}
        onClose={onChangeDeviceTagDialogClose}
        onSubmit={onChangeDeviceTags}
      />
      <DeviceTagModal
        isOpen={isEditTagDialogOpen}
        onClose={onEditTagDialogClose}
        onUpdateDeviceTag={onUpdateDeviceTag}
        loading={deviceTagMutation.isLoading}
        activeTags={tags}
        onDeleteTag={onDeleteTag}
      />
    </Container>
  );
};

export default DeviceGeneralSection;
