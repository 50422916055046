import { memo, useCallback, useMemo, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from 'src/components/Table/TableContainer';
import TableHeading from 'src/components/Table/TableHeading';
import LoadingRows from 'src/components/Table/LoadingRows';
import Row from 'src/components/Table/Row';
import NoDataCell from 'src/components/NoDataCell';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFetchUnmatchedFiles from './useFetchUnmatchedFiles';
import { defaultUnmatchedFilesFilters, headings } from './constants';
import { columnsOrder } from './constants';
import useResetSearchParams from 'src/queries/useResetSearchParams';
import useQueryParams from 'src/queries/useQueryParams';
import useTableSort from 'src/queries/useTableSort';
import { checkHasFilters } from 'src/utils/table.util';
import useDebounceHandler from 'src/queries/useDebounceHandler';
import SearchInputRow from 'src/components/SearchInputRow';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

const UnmatchedFilesTable = () => {
  const { deviceId } = useParams();
  useResetSearchParams();

  const {
    isLoading,
    data: logs,
    lastElementRef,
    isFetching,
  } = useFetchUnmatchedFiles({ deviceId: deviceId });

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue },
    onApplySearchParams,
    onResetSearchParams,
    changeFieldInURL,
  } = useQueryParams();

  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const { onSort, onSortTable, onResetSortField, getListItemSortMethod, getCellSortMethod } =
    useTableSort({
      filters,
      onApplySearchParams,
      searchValue,
      defaultFilters: defaultUnmatchedFilesFilters,
    });

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([{ key, value }]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler, setSearchValue],
  );

  const hasFilters = useMemo(() => checkHasFilters(filters), [filters]);

  const onResetSearch = useCallback(() => {
    onResetSearchParams();
    setSearchValue('');
  }, [onResetSearchParams, setSearchValue]);

  const onCellSort = useCallback(
    ({ field, sortingCellField, sortMethod, sortFieldName }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField, sortFieldName);
    },
    [onSort],
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <TableContainer
          square
          component={Paper}
          elevation={0}
          styles={{
            maxHeight: 'calc(100vh - 316px)',
          }}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHeading
              headings={headings}
              hasFilter={false}
              hasFilters={hasFilters}
              onCellSort={onCellSort}
              onSortTable={onSortTable}
              onResetSortField={onResetSortField}
              getListItemSortMethod={getListItemSortMethod}
              getCellSortMethod={getCellSortMethod}
              listCellStyles={{
                top: '56px',
                padding: '10px 16px',
              }}>
              <SearchInputRow
                searchValue={searchValue}
                onChangeSearchValue={onChangeSearchValue}
                onResetSearch={onResetSearch}
                placeholder="Enter a keyword"
                cellStyles={{
                  top: '0',
                }}
              />
            </TableHeading>

            <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
              {isLoading ? (
                <LoadingRows columnCount={5} rowCount={3} />
              ) : (
                <>
                  {!!logs?.length &&
                    logs.map((row, index) => (
                      <Row
                        hasActions={false}
                        ref={index === logs.length - 1 ? lastElementRef : null}
                        key={row?.id}
                        row={row}
                        columnData={columnsOrder}
                      />
                    ))}
                  {!isLoading && isFetching && <LoadingRows columnCount={5} rowCount={3} />}
                  {!isLoading && !logs?.length && (
                    <NoDataCell colSpan={5}>No files yet.</NoDataCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default memo(UnmatchedFilesTable);
