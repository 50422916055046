import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Auth from '../components/Auth';
import Page from '../components/Page';
import useValidateInvitation from '../queries/useValidateInvitation';
import AlertPopup from '../components/AlertPopup';
import { colors } from '../theme/palette/darkPalette';
import AuthSubmitButton from '../components/AuthSubmitButton';
import { pagesText } from '../constants/pagesText';

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 375,
  width: '100%',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 300,
  lineHeight: '16px',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const EmailInvitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, customerId, isNew, token } = location.state;
  const { data: response } = useValidateInvitation({ token });

  const title = pagesText.emailInvitationPage.title[isNew ? 'setupAccount' : 'acceptInvitation'];
  const buttonText =
    pagesText.emailInvitationPage.buttonText[isNew ? 'setupAccount' : 'acceptInvitation'];

  const onNavigate = useCallback(() => {
    navigate(`${isNew ? '/setup-account' : '/login'}?email=${email}&customerId=${customerId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, email, customerId]);

  const MemoizedActions = useCallback(
    () => (
      <Stack direction="column" alignItems="center" gap={2}>
        <AuthSubmitButton onClick={onNavigate}>{buttonText}</AuthSubmitButton>
      </Stack>
    ),
    [buttonText, onNavigate],
  );

  if (!response) return null;

  if (response && !response.isValid) {
    return (
      <Page title="sd">
        <AlertPopup
          title={pagesText.emailValidationPopup.title}
          backgroundColor={colors.darkGrey}
          styles={{
            height: 222,
          }}>
          <Description>{pagesText.emailValidationPopup.description}</Description>
        </AlertPopup>
      </Page>
    );
  }

  return (
    <Page title={title}>
      <Auth title={title} Actions={MemoizedActions} />
    </Page>
  );
};

export default EmailInvitation;
