import api from './api';

const API_PREFIX = '/dashboard';
const API_DEVICE_PREFIX = '/device';

const getWDACResults = ({ limit, page, customerEnvId }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/wdacResults`, {
    params: {
      Page: page,
      Limit: limit,
      'OrderWdacQuery.SortBy': 'arp_display_name',
      'OrderWdacQuery.Order': 'DESC',
    },
  });

const getDeiveWDACResults = ({ limit, page, customerEnvId, deviceId }) =>
  api.get(`${API_DEVICE_PREFIX}/${customerEnvId}/${deviceId}/wdacResults`, {
    params: {
      Page: page,
      Limit: limit,
      'OrderWdacQuery.SortBy': 'arp_display_name',
      'OrderWdacQuery.Order': 'DESC',
    },
  });

const WDACResultsService = {
  getWDACResults,
  getDeiveWDACResults,
};

export default WDACResultsService;
