import CustomCell from 'src/components/Table/CustomCell';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

export const columnsOrder = [
  { name: 'processName', styles: { wordBreak: 'break-word' } },
  { name: 'fullFilePath', styles: { wordBreak: 'break-word' } },
  { name: 'deviceWdacEventsCount', styles: { minWidth: '60px' } },
  { name: 'authenticodeHashSha256', styles: { wordBreak: 'break-word' } },
  { name: 'eventSystemTime', styles: { minWidth: '106px' } },
];

export const defaultUnmatchedFilesFilters = {
  FilterWdac: [
    [
      {
        name: 'Name',
        type: 'date',
        value: '',
        label: 'Name',
      },
    ],
  ],
};

export const headings = [
  {
    title: 'Process Name',
    name: 'processName',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterWdac',
    sortingCellField: 'process_name',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Full File Path',
    name: 'fullFilePath',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterWdac',
    sortingCellField: 'full_file_path',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Device Wdac Events Count',
    name: 'deviceWdacEventsCount',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterWdac',
    sortingCellField: 'device_wdac_events_count',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Authenticode Hash Sha256',
    name: 'authenticodeHashSha256',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterWdac',
    sortingCellField: 'authenticode_hash_sha256',
    sortFieldName: 'OrderWdacQuery',
  },
  {
    title: 'Event System Time',
    name: 'eventSystemTime',
    hasSorting: false,
    sortMethod: sortingMethods.initial,
    Component: CustomCell,
    field: 'FilterWdac',
    sortingCellField: 'event_system_time',
    sortFieldName: 'OrderWdacQuery',
  },
];
