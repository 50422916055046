import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useApplication from '../../../queries/appDetails/useApplication';
import { pagesText } from '../../../constants/pagesText';
import { Loader } from '../../../components';
import { isDateString } from '../../../utils/date.util';
import { formatDate } from '../../../utils/formatTime';

const dataToRender = [
  'installBlockedByDynamicCodeSecurity',
  'executionBlockedByDynamicCodeSecurity',
  'installTrustedByISG',
  'executionTrustedByISG',
  'executionTrustedByISGDate',
  'installTrustedByISGDate',
  'filesCount',
];

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      width: '100%',
    })}>
    {children}
  </Box>
);

const AppGeneralSection = () => {
  const { appId } = useParams();
  const { isLoading, data: apps } = useApplication({ appId });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {isLoading && <Loader />}
      <Stack spacing={2}>
        <Table>
          <TableBody>
            {!isLoading &&
              apps &&
              dataToRender.map(field => (
                <TableRow key={field}>
                  <TableCell>{pagesText.appDetails.generalSection[field]}:</TableCell>
                  <TableCell>
                    {isDateString(apps[field])
                      ? formatDate(apps[field])
                      : typeof apps[field] === 'boolean'
                        ? `${apps[field]}`
                        : apps[field] || '-'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Stack>
    </Container>
  );
};

export default AppGeneralSection;
