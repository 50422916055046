import { Stack } from '@mui/material';
import { colors } from 'src/theme/palette/darkPalette';

const FilterCount = ({ filteredCount }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      color={colors.green}
      sx={{
        width: 'fit-content',
        minHeight: '24px',
        ml: 'auto',
        p: '0 8px',
        minWidth: '80px',
        borderRadius: 1,
        fontSize: '14px',
        position: 'absolute',
        top: '-28px',
        right: 0,
      }}>
      {filteredCount !== null ? `Filtered (${filteredCount})` : null}
    </Stack>
  );
};

export default FilterCount;
