import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TabsPageLayout from '../../../components/TabsPageLayout';
import { PORTAL_ROUTE } from '../../../constants/routes';
import useApplication from '../../../queries/appDetails/useApplication';

const AppDetails = () => {
  const { appId } = useParams();
  const { data: apps } = useApplication({ appId });

  const tabs = useMemo(
    () => [
      {
        label: 'General',
        value: `${PORTAL_ROUTE}/apps/${appId}/general`,
      },
      {
        label: 'Details',
        value: `${PORTAL_ROUTE}/apps/${appId}/details`,
      },
    ],
    [appId],
  );

  return (
    <TabsPageLayout
      hasBackButton
      title={apps?.environmentApplicationName}
      tabs={tabs}
      backNavigationPath={`${PORTAL_ROUTE}/apps`}
      wrapperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
      titleProps={{
        fontSize: '1.4rem',
      }}
    />
  );
};

export default AppDetails;
