import api from './api';

const addEnvironment = ({ name, configManagerSupportId, description, location, customerId }) =>
  api.post('/customerEnvironment', {
    name,
    configManagerSupportId,
    description,
    location,
    customerId,
  });

const getEnvironments = () => api.get('/customerEnvironment');

const getFilesCount = ({ customerEnvId, start, end, type }) =>
  api.get(`customerEnvironment/${customerEnvId}/filesCount`, {
    params: {
      ...(start ? { 'DateRange.Start': start } : {}),
      ...(end ? { 'DateRange.End': end } : {}),
      Type: type,
    },
  });

const editEnvironment = ({ customerEnvId, configManagerSupportId, description, location, name }) =>
  api.put(`customerEnvironment/${customerEnvId}`, {
    name,
    configManagerSupportId,
    description,
    location,
  });

const changeActiveEnvironment = ({ customerEnvId }) =>
  api.put(`customerEnvironment/${customerEnvId}/activate`);

const deleteEnvironment = ({ customerEnvId, force }) =>
  api.delete(`customerEnvironment/${customerEnvId}`, null, {
    params: {
      force,
    },
  });

const changeEnvironmentDeviceLoggingKey = ({ customerEnvId }) =>
  api.put(`customerEnvironment/${customerEnvId}/setDeviceLoggingApiKey`);

const EnvironmentService = {
  addEnvironment,
  getEnvironments,
  editEnvironment,
  deleteEnvironment,
  changeActiveEnvironment,
  changeEnvironmentDeviceLoggingKey,
  getFilesCount,
};

export default EnvironmentService;
