import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TabsPageLayout from '../../../components/TabsPageLayout';
import { PORTAL_ROUTE } from '../../../constants/routes';
import useDevice from '../../../queries/deviceDetails/useDevice';

const AppDetails = () => {
  const { deviceId } = useParams();
  const { data: device } = useDevice({ deviceId });

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: 'General',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/general`,
      },
      {
        label: 'Win32 Apps',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/win32-apps`,
      },
      {
        label: 'Unmatched Files',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/unmatched-files`,
      },
      {
        label: 'Logs',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/logs`,
      },
    ];

    return allTabs;
  }, [deviceId]);

  return (
    <TabsPageLayout
      hasBackButton
      title={device?.device?.name}
      tabs={tabs}
      backNavigationPath={`${PORTAL_ROUTE}/devices`}
      wrapperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
      titleProps={{
        fontSize: '1.4rem',
      }}
    />
  );
};

export default AppDetails;
