import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { DEVICE_TAGS_QUERY_KEY } from '../constants/query';
import TagService from '../services/tag.service';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';
import useSubscribeStorage from './useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

const useFetchDeviceTags = ({ debouncedSearchValue, enabled }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const { data, ...props } = useInfiniteQuery(
    [DEVICE_TAGS_QUERY_KEY, debouncedSearchValue],
    async ({ pageParam = 1 }) =>
      await TagService.getTags({
        customerEnvId,
        page: pageParam,
        limit: EXPANDED_ROWS_LIMIT,
        search: debouncedSearchValue,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const isMoreData = lastPage.tags.length >= EXPANDED_ROWS_LIMIT;

        if (isMoreData) {
          return currentPage + 1;
        }

        return undefined;
      },
      enabled,
    },
  );

  const fetchedData = useMemo(() => {
    const duplicateFilteredList = data?.pages?.reduce(
      (acc, page) => {
        acc.data = [...acc.data, ...page.tags];
        acc.count = Math.max(acc.count, page.count);
        if (page?.totalCount) acc.totalCount = page.totalCount;
        return acc;
      },
      {
        data: [],
        count: 0,
      },
    );

    const lastPage = data?.pages[data.pages?.length - 1];
    if (!lastPage)
      return {
        data: [],
        count: 0,
      };
    if (Object.keys(lastPage).length < 2) {
      return {
        ...lastPage,
        ...lastPage.tags,
      };
    }

    return {
      ...lastPage,
      ...duplicateFilteredList,
    };
  }, [data]);

  const tags = useMemo(() => fetchedData?.data || [], [fetchedData?.data]);

  return {
    ...props,
    tags,
    fetchedData,
  };
};

export default useFetchDeviceTags;
