import { pagesText } from '../../constants/pagesText';
import EnvironmentGuard from '../../components/EnvironmentGuard';
import Page from '../../components/Page';
import ResultsTable from './ResultsTable';
import useFetchWDACResults from 'src/queries/useFetchWDACResults';
import useQueryData from 'src/queries/useQueryData';
import { CUSTOMER_QUERY_KEY } from 'src/constants/query';
import { companyNameSelector } from 'src/selectors/customer';
import { PORTAL_ROUTE } from 'src/constants/routes';
import { columnsOrder, secondHeadings, topHeading } from './constants';

const WDACResults = () => {
  const companyName = useQueryData(CUSTOMER_QUERY_KEY, companyNameSelector);
  const { isLoading, data: results, lastElementRef, isFetching } = useFetchWDACResults();

  return (
    <Page
      title={pagesText.dashboard.title}
      breadcrumbs={[
        { label: companyName, route: `${PORTAL_ROUTE}/apps` },
        { label: 'WDAC Results' },
      ]}>
      <EnvironmentGuard>
        <ResultsTable
          isLoading={isLoading}
          results={results}
          lastElementRef={lastElementRef}
          isFetching={isFetching}
          topHeading={topHeading}
          secondHeadings={secondHeadings}
          columnsOrder={columnsOrder}
        />
      </EnvironmentGuard>
    </Page>
  );
};

export default WDACResults;
