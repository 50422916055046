import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import LoadingRows from '../../components/Table/LoadingRows';
import Row from '../../components/Table/Row';
import NoDataCell from '../../components/NoDataCell';
import { pagesText } from '../../constants/pagesText';
import useResetSearchParams from '../../queries/useResetSearchParams';
import TableContainer from '../../components/Table/TableContainer';
import { Paper, Stack, TableCell, TableHead, TableRow } from '@mui/material';
import useTypographyMediaQuery from 'src/queries/useTypographyMediaQuery';
import TableHeadTypography from 'src/components/TableHeadTypography';
import { ROW_SOURCES } from 'src/constants/table';

const ResultsTable = ({
  isLoading,
  results,
  lastElementRef,
  isFetching,
  topHeading,
  secondHeadings,
  columnsOrder,
  isDeviceTable = false,
}) => {
  useResetSearchParams();

  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'unset',
        mt: isDeviceTable ? 0 : 4,
      }}>
      <TableContainer
        styles={{ maxHeight: `calc(100vh - ${isDeviceTable ? '382px' : '242px'})` }}
        square
        component={Paper}
        elevation={0}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: '#3f3f3f', borderRadius: 0 }}>
            <TableRow>
              {topHeading.map((el, index) => {
                return (
                  <TableCell
                    key={`${el.title}-${index}`}
                    mediumScreen={mediumScreen}
                    largeScreen={largeScreen}
                    colSpan={el.colSpan}
                    rowSpan={el.rowSpan}
                    sx={{
                      top: '0',
                      p: 1,
                      borderRight: el.borderRight ? '2px solid #2e3236' : 'unset',
                    }}>
                    <Stack gap={1} direction="row" alignItems="center" justifyContent={'center'}>
                      <TableHeadTypography
                        mediumScreen={mediumScreen}
                        largeScreen={largeScreen}
                        smallScreen={smallScreen}>
                        {el.title}
                      </TableHeadTypography>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {secondHeadings.map((el, index) => {
                return (
                  <TableCell
                    key={`${el.title}-${index}`}
                    mediumScreen={mediumScreen}
                    largeScreen={largeScreen}
                    colSpan={1}
                    sx={{
                      p: '8px 16px',
                      top: '31px',
                      borderRight: el.borderRight ? '2px solid #2e3236' : 'unset',
                    }}>
                    <Stack gap={1} direction="row" alignItems="center">
                      <TableHeadTypography
                        alignItems={'center'}
                        mediumScreen={mediumScreen}
                        largeScreen={largeScreen}
                        smallScreen={smallScreen}>
                        {el.title}
                      </TableHeadTypography>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
            {isLoading ? (
              <LoadingRows columnCount={isDeviceTable ? 8 : 10} rowCount={5} />
            ) : (
              <>
                {results?.map((row, index) => (
                  <Row
                    isClickable={isDeviceTable}
                    source={isDeviceTable ? ROW_SOURCES.wdac : null}
                    ref={results?.length - 1 === index ? lastElementRef : null}
                    key={row.id}
                    row={row}
                    columnData={columnsOrder}
                    hasActions={false}
                  />
                ))}
                {!isLoading && isFetching && (
                  <LoadingRows columnCount={isDeviceTable ? 8 : 10} rowCount={3} />
                )}
                {!isLoading && !results?.length && (
                  <NoDataCell colSpan={columnsOrder.length + 1}>{pagesText.noDataFound}</NoDataCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ResultsTable;
