import React, { Suspense } from 'react';
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom';

import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';

// non lazy loaded pages
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/Page404';
import FAQ from './pages/FAQ';
import { Loader } from './components';
import Environments from './pages/company/environments/Environments';
import General from './pages/profile/general';
import { profileTabs } from './pages/profile/constants';
import TabsPageLayout from './components/TabsPageLayout';
import { PORTAL_ROUTE } from './constants/routes';
import Company from './pages/company';
import { companyDetailActionType } from './constants/company';
import EmailInvitation from './pages/EmailInvitation';
import Devices from './pages/devices';
import AppDetails from './pages/apps/appsDetails/AppDetails';
import AppGeneralSection from './pages/apps/appsDetails/AppGeneralSection';
import AppDetailsSection from './pages/apps/appsDetails/AppDetailsSection';
import DevicesDashboard from './pages/devices-dashboard';
import DeviceDetails from './pages/devices/deviceDetails/DeviceDetails';
import DeviceGeneralSection from './pages/devices/deviceDetails/general/DeviceGeneralSection';
import DeviceAppResultsSection from './pages/devices/deviceDetails/DeviceAppResultsSection';
import DeviceLogsSection from './pages/devices/deviceDetails/deviceLogs/DeviceLogsSection';
import WDACResults from './pages/wdac-results';
import UnmatchedFilesSection from './pages/devices/deviceDetails/unmatchedFiles/UnmatchedFiles';
// lazy loaded components for code splitting based on routes
const PortalLayout = React.lazy(() => import('./layouts/portal'));
const Login = React.lazy(() => import('./pages/login/Login'));
const Apps = React.lazy(() => import('./pages/apps/Apps'));
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword/ForgotPassword'));
const PasswordReset = React.lazy(() => import('./pages/resetPassword/PasswordReset'));
// const Verify = React.lazy(() => import('./pages/Verify'));
const BulkDownloads = React.lazy(() => import('./pages/bulkDownloads/BulkDownloads'));
const CompanyDetails = React.lazy(() => import('./pages/company/companyDetails'));
const CompanyUsers = React.lazy(() => import('./pages/company/users'));
// ----------------------------------------------------------------------
const Invites = React.lazy(() => import('./pages/company/invite'));
const Companies = React.lazy(() => import('./pages/companies'));
const SetupAccount = React.lazy(() => import('./pages/setupAccount'));

export default function Router() {
  const location = useLocation();

  return useRoutes([
    {
      path: PORTAL_ROUTE,
      element: (
        <Suspense fallback={<Loader />}>
          <PortalLayout />
        </Suspense>
      ),
      children: [
        {
          path: '',
          element: (
            <Navigate
              to={{
                pathname: '/portal/apps',
                search: location.search,
              }}
            />
          ),
        },
        {
          path: 'apps',
          element: <Outlet />,
          children: [
            { path: '', element: <Apps /> },
            {
              path: ':appId',
              element: <AppDetails />,
              children: [
                {
                  path: 'general',
                  element: <AppGeneralSection />,
                },
                {
                  path: 'details',
                  element: <AppDetailsSection />,
                },
              ],
            },
          ],
        },
        {
          path: 'accounts',
          element: <Outlet />,
          children: [],
        },
        {
          path: 'profile',
          element: <TabsPageLayout title="Account" tabs={profileTabs} />,
          children: [{ path: 'general', element: <General /> }],
        },
        {
          path: 'company',
          element: <Company />,
          children: [
            {
              path: 'create',
              element: <CompanyDetails actionType={companyDetailActionType.create} />,
            },
            { path: 'edit', element: <CompanyDetails /> },
            { path: 'users', element: <CompanyUsers /> },
            { path: 'invite-user', element: <Invites /> },
            { path: 'environments', element: <Environments /> },
          ],
        },
        {
          path: 'bulk-download',
          element: <BulkDownloads />,
        },
        {
          path: 'companies',
          element: <Companies />,
        },
        {
          path: 'devices',
          element: <Outlet />,
          children: [
            { path: '', element: <Devices /> },
            {
              path: ':deviceId',
              element: <DeviceDetails />,
              children: [
                {
                  path: 'general',
                  element: <DeviceGeneralSection />,
                },
                {
                  path: 'win32-apps',
                  element: <DeviceAppResultsSection />,
                },
                {
                  path: 'logs',
                  element: <DeviceLogsSection />,
                },
                {
                  path: 'unmatched-files',
                  element: <UnmatchedFilesSection />,
                },
              ],
            },
          ],
        },
        {
          path: 'devices-dashboard',
          element: <DevicesDashboard />,
        },
        {
          path: 'wdac-results',
          element: <WDACResults />,
        },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/404',
      element: <NotFound />,
    },
    {
      path: '/public',
      element: <Outlet />,
      children: [{ path: 'privacyPolicy', element: <PrivacyPolicy /> }],
    },
    {
      path: '/faq',
      element: <FAQ />,
    },
    {
      path: '/',
      element: <UnauthenticatedLayout />,
      children: [
        {
          path: '/',
          element: (
            <Navigate
              to={{
                pathname: '/portal/apps',
                search: location.search,
              }}
              replace
            />
          ),
        },
        { path: 'login', element: <Login /> },
        // Todo: temp removed. Revert or remove it in the future
        // { path: 'register', element: <Register /> },
        {
          path: 'setup-account',
          element: <SetupAccount />,
        },
        {
          path: 'email-invitation',
          element: <EmailInvitation />,
        },
        { path: 'forgotPassword', element: <ForgotPassword /> },
        { path: 'passwordReset', element: <PasswordReset /> },
        // { path: '/verify', element: <Verify /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ]);
}
