import { memo } from 'react';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from 'src/components/Table/TableContainer';
import TableHeading from 'src/components/Table/TableHeading';
import LoadingRows from 'src/components/Table/LoadingRows';
import Row from 'src/components/Table/Row';
import NoDataCell from 'src/components/NoDataCell';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFetchUnmatchedFiles from './useFetchUnmatchedFiles';
import { headings } from './constants';
import { columnsOrder } from './constants';

const UnmatchedFilesTable = () => {
  const { deviceId } = useParams();

  const {
    isLoading,
    data: logs,
    lastElementRef,
    isFetching,
  } = useFetchUnmatchedFiles({ deviceId: deviceId });

  return (
    <Box sx={{ position: 'relative' }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <TableContainer
          square
          component={Paper}
          elevation={0}
          styles={{
            maxHeight: 'calc(100vh - 384px)',
          }}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHeading
              headings={headings}
              hasFilter={false}
              hasHeaderCorner
              listCellStyles={{
                top: '0px',
                padding: '10px 16px',
              }}
            />

            <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
              {isLoading ? (
                <LoadingRows columnCount={5} rowCount={3} />
              ) : (
                <>
                  {!!logs?.length &&
                    logs.map((row, index) => (
                      <Row
                        hasActions={false}
                        ref={index === logs.length - 1 ? lastElementRef : null}
                        key={row?.id}
                        row={row}
                        columnData={columnsOrder}
                      />
                    ))}
                  {!isLoading && isFetching && <LoadingRows columnCount={5} rowCount={3} />}
                  {!isLoading && !logs?.length && (
                    <NoDataCell colSpan={5}>No files yet.</NoDataCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default memo(UnmatchedFilesTable);
