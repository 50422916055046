import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components';
import useFetchDeviceWDACResults from 'src/queries/useFetchDeviceWDACResults';
import {
  columnsOrderDevice,
  secondHeadingsDevice,
  topHeadingDevice,
} from 'src/pages/wdac-results/constants';
import ResultsTable from 'src/pages/wdac-results/ResultsTable';

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      width: '100%',
      textAlign: 'center',
    })}>
    {children}
  </Box>
);

const DeviceAppResultsSection = () => {
  const { deviceId } = useParams();

  const {
    isLoading,
    data: results,
    lastElementRef,
    isFetching,
  } = useFetchDeviceWDACResults({ deviceId });

  if (isLoading) {
    return (
      <Box mt={'80px'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Container>
      <ResultsTable
        isLoading={isLoading}
        results={results}
        lastElementRef={lastElementRef}
        isFetching={isFetching}
        topHeading={topHeadingDevice}
        secondHeadings={secondHeadingsDevice}
        columnsOrder={columnsOrderDevice}
        isDeviceTable={true}
      />
    </Container>
  );
};

export default DeviceAppResultsSection;
