import { useParams } from 'react-router-dom';
import useFetchDeviceWDACResults from 'src/queries/useFetchDeviceWDACResults';
import AppResultTable from './AppResultTable';
import { columnsOrderDevice, secondHeadingsDevice, topHeadingDevice } from './constants';

const DeviceAppResultsSection = () => {
  const { deviceId } = useParams();

  const {
    isLoading,
    data: results,
    lastElementRef,
    isFetching,
  } = useFetchDeviceWDACResults({ deviceId });

  return (
    <AppResultTable
      isLoading={isLoading}
      results={results}
      lastElementRef={lastElementRef}
      isFetching={isFetching}
      topHeading={topHeadingDevice}
      secondHeadings={secondHeadingsDevice}
      columnsOrder={columnsOrderDevice}
    />
  );
};

export default DeviceAppResultsSection;
