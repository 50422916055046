import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import LoadingRows from '../../../../components/Table/LoadingRows';
import Row from '../../../../components/Table/Row';
import NoDataCell from '../../../../components/NoDataCell';
import { pagesText } from '../../../../constants/pagesText';
import useResetSearchParams from '../../../../queries/useResetSearchParams';
import TableContainer from '../../../../components/Table/TableContainer';
import { Paper, Stack, TableCell, TableHead, TableRow } from '@mui/material';
import useTypographyMediaQuery from 'src/queries/useTypographyMediaQuery';
import TableHeadTypography from 'src/components/TableHeadTypography';
import { ROW_SOURCES } from 'src/constants/table';
import SearchInputRow from 'src/components/SearchInputRow';
import useQueryParams from 'src/queries/useQueryParams';
import { useCallback, useState } from 'react';
import useTableSort from 'src/queries/useTableSort';
import useDebounceHandler from 'src/queries/useDebounceHandler';
import { defaultAppresultFilters } from './constants';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

const AppResultTable = ({
  isLoading,
  results,
  lastElementRef,
  isFetching,
  topHeading,
  secondHeadings,
  columnsOrder,
}) => {
  useResetSearchParams();

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue },
    onApplySearchParams,
    onResetSearchParams,
    changeFieldInURL,
  } = useQueryParams();

  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  const { onSort, onResetSortField, getListItemSortMethod, getCellSortMethod } = useTableSort({
    filters,
    onApplySearchParams,
    searchValue,
    defaultFilters: defaultAppresultFilters,
  });

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([{ key, value }]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler, setSearchValue],
  );

  const onResetSearch = useCallback(() => {
    onResetSearchParams();
    setSearchValue('');
  }, [onResetSearchParams, setSearchValue]);

  const onCellSort = useCallback(
    ({ field, sortingCellField, sortMethod, sortFieldName }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField, sortFieldName);
    },
    [onSort],
  );

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'unset',
        mt: 0,
      }}>
      <TableContainer
        styles={{ maxHeight: `calc(100vh - 318px)` }}
        square
        component={Paper}
        elevation={0}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: '#3f3f3f', borderRadius: 0 }}>
            <SearchInputRow
              searchValue={searchValue}
              onChangeSearchValue={onChangeSearchValue}
              onResetSearch={onResetSearch}
              placeholder="Enter a keyword"
              cellStyles={{
                top: '0',
              }}
            />
            <TableRow>
              {topHeading.map((el, index) => {
                return (
                  <TableCell
                    key={`${el.title}-${index}`}
                    mediumScreen={mediumScreen}
                    largeScreen={largeScreen}
                    colSpan={el.colSpan}
                    rowSpan={el.rowSpan}
                    sx={{
                      top: '56px',
                      p: 1,
                      borderRight: el.borderRight ? '2px solid #2e3236' : 'unset',
                      borderBottom: '2px solid #2e3236',
                    }}>
                    <Stack gap={1} direction="row" alignItems="center" justifyContent={'center'}>
                      <TableHeadTypography
                        mediumScreen={mediumScreen}
                        largeScreen={largeScreen}
                        smallScreen={smallScreen}>
                        {el.title}
                      </TableHeadTypography>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {secondHeadings.map(({ Component, ...headingProps }, index) => {
                const sortMethod = !getListItemSortMethod
                  ? sortingMethods.initial
                  : getListItemSortMethod(headingProps?.name);

                return (
                  <TableCell
                    key={`${headingProps.title}-${index}`}
                    mediumScreen={mediumScreen}
                    largeScreen={largeScreen}
                    colSpan={1}
                    sx={{
                      p: '8px 16px',
                      top: '88px',
                      borderRight: headingProps.borderRight ? '2px solid #2e3236' : 'unset',
                    }}>
                    <Stack gap={1} direction="row" alignItems="center">
                      {!!Component ? (
                        <Component
                          title={headingProps.title}
                          name={headingProps.name}
                          onDoubleClick={onResetSortField}
                          onCellSort={onCellSort}
                          getCellSortMethod={getCellSortMethod}
                          filterFieldName={headingProps.filterFieldName}
                          sortingCellField={headingProps.sortingCellField}
                          sortFieldName={headingProps.sortFieldName}
                          field={headingProps.field}
                        />
                      ) : (
                        <TableHeadTypography
                          onDoubleClick={() => onResetSortField(headingProps.name)}
                          mediumScreen={mediumScreen}
                          largeScreen={largeScreen}
                          smallScreen={smallScreen}
                          hasSorting={sortMethod !== sortingMethods.initial}>
                          {headingProps.title}
                        </TableHeadTypography>
                      )}
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
            {isLoading ? (
              <LoadingRows columnCount={8} rowCount={5} />
            ) : (
              <>
                {results?.map((row, index) => (
                  <Row
                    isClickable={true}
                    source={ROW_SOURCES.wdac}
                    ref={results?.length - 1 === index ? lastElementRef : null}
                    key={row.id}
                    row={row}
                    columnData={columnsOrder}
                    hasActions={false}
                  />
                ))}
                {!isLoading && isFetching && <LoadingRows columnCount={8} rowCount={3} />}
                {!isLoading && !results?.length && (
                  <NoDataCell colSpan={columnsOrder.length + 1}>{pagesText.noDataFound}</NoDataCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default AppResultTable;
