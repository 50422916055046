import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import useInfiniteFetch from '../../queries/useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from '../../constants/table';
import useDebounce from '../../queries/auth/useDebounce';
import { colors } from '../../theme/palette/darkPalette';
import DevicesChartTagFilter from 'src/pages/devices-dashboard/DevicesChartTagFilter';

const StyledTextField = styled(TextField)({
  '& svg': {
    fill: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green,
    },
  },
});

const FilterAutocomplete = ({
  selectedValue,
  onChangeFilter,
  fieldIndex,
  sectionIndex,
  queryKey,
  fetchFunction,
  expandedRowsLimit = EXPANDED_ROWS_LIMIT,
  itemName,
  source,
}) => {
  const [searchValue, setSearchValue] = useState(selectedValue);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const isInitialSelectedValueSet = useRef(false);

  const { data, lastElementRef, isLoading } = useInfiniteFetch({
    queryKey,
    fetchFunction,
    expandedRowsLimit,
    itemName,
    searchValue: debouncedSearchValue,
  });

  useEffect(() => {
    if (selectedValue && !!searchValue && !isInitialSelectedValueSet.current) {
      setSearchValue(selectedValue);
      isInitialSelectedValueSet.current = true;
    }
  }, [itemName, searchValue, selectedValue]);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);

      if (!event.target.value) {
        onChangeFilter({ source, fieldIndex, sectionIndex, fieldValue: '' });
      }
    },
    [setSearchValue, onChangeFilter, fieldIndex, sectionIndex, source],
  );

  const onChnageTagInput = useCallback(
    (tagNames, tagIds) => {
      onChangeFilter({
        source,
        fieldIndex,
        sectionIndex,
        fieldValue: tagIds || [],
      });
    },
    [fieldIndex, onChangeFilter, sectionIndex, source],
  );

  const formattedData = data.map(item => ({
    value: item?.name || item,
    label: item?.name || item,
  }));

  const tagOptions = useMemo(() => {
    if (itemName === 'tags' && Array.isArray(selectedValue)) {
      return selectedValue.map(id => data.find(tag => tag.id === id)).filter(Boolean) || [];
    } else {
      return [];
    }
  }, [itemName, selectedValue, data]);

  return (
    <>
      {itemName === 'tags' ? (
        <DevicesChartTagFilter
          isHorizontal={true}
          onApplyTagFilter={onChnageTagInput}
          tagOptions={tagOptions}
        />
      ) : (
        <Autocomplete
          disableClearable
          value={debouncedSearchValue}
          onChange={(event, data) => {
            onChangeFilter({
              source,
              fieldIndex,
              sectionIndex,
              fieldValue: data?.value || '',
            });
          }}
          isOptionEqualToValue={(option, selectedOption) => option?.value === selectedOption?.value}
          options={formattedData}
          loading={isLoading}
          renderInput={params => (
            <StyledTextField
              {...params}
              placeholder="Select"
              value={searchValue}
              onChange={onChangeSearchValue}
              InputLabelProps={{
                sx: {
                  color: '#fff',
                },
              }}
            />
          )}
          ref={lastElementRef}
        />
      )}
    </>
  );
};

export default FilterAutocomplete;
