export const logsColumnsOrder = ['logFileName', 'logFileType', 'modified', 'size', 'actions', ''];

export const logscustomHeader = {
  modified: 'modified',
  size: 'size',
  actions: 'actions',
};

export const devicelogTypes = {
  'Policy load events (ID 3099)': 'CodeIntegrityOperational3099',
  'Win32 audit events (ID 3076)': 'CodeIntegrityOperational3076',
  'Win32 enforced events (ID 3077)': 'CodeIntegrityOperational3077',
};

export const logsHeadings = [
  {
    title: 'Name',
    name: 'logFileName',
    hasSorting: false,
  },
  {
    title: 'Type',
    name: 'logFileType',
    hasSorting: false,
  },
  {
    title: 'Date',
    name: 'modified',
    hasSorting: false,
  },
  {
    title: 'Size',
    name: 'size',
    hasSorting: false,
  },
  {
    title: 'Actions',
    name: 'actions',
    hasSorting: false,
  },
];
