import { useCallback } from 'react';
import useInfiniteFetch from './useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';
import useSubscribeStorage from './useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';
import WDACResultsService from 'src/services/wdac-results.service';
import { WDAC_DEVICE_RESULTS_QUERY_KEY } from 'src/constants/query';

const useFetchDeviceWDACResults = ({ deviceId }) => {
  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      WDACResultsService.getDeiveWDACResults({
        limit,
        page,
        customerEnvId,
        deviceId,
      }),
    [customerEnvId, deviceId],
  );

  const response = useInfiniteFetch({
    queryKey: [WDAC_DEVICE_RESULTS_QUERY_KEY, deviceId, customerEnvId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'results',
  });

  return {
    ...response,
  };
};

export default useFetchDeviceWDACResults;
