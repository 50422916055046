export const columnsOrder = [
  { name: 'processName', styles: { wordBreak: 'break-word' } },
  { name: 'fullFilePath', styles: { wordBreak: 'break-word' } },
  { name: 'deviceWdacEventsCount', styles: { minWidth: '60px' } },
  { name: 'authenticodeHashSha256', styles: { wordBreak: 'break-word' } },
  { name: 'eventSystemTime', styles: { minWidth: '106px' } },
];

export const headings = [
  {
    title: 'Process Name',
    name: 'processName',
  },
  {
    title: 'Full File Path',
    name: 'fullFilePath',
  },
  {
    title: 'Device Wdac Events Count',
    name: 'deviceWdacEventsCount',
  },
  {
    title: 'Authenticode Hash Sha256',
    name: 'authenticodeHashSha256',
  },
  {
    title: 'Event System Time',
    name: 'eventSystemTime',
  },
];
