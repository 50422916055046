import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CompanySectionContainer from '../../../components/CompanySectionContainer';
import Button from '../../../components/Button';
import EnvironmentsList from './EnvironmentsList';
import { pagesText } from '../../../constants/pagesText';
import useQueryData from '../../../queries/useQueryData';
import {
  APPLICATIONS_QUERY_KEY,
  CUSTOMER_QUERY_KEY,
  DEVICES_QUERY_KEY,
  ENVIRONEMNTS_QUERY_KEY,
  USER_QUERY_KEY,
} from '../../../constants/query';
import DeleteEnvironmentDialog from './DeleteEnvironmentDialog';
import useDialog from '../../../queries/useDialog';
import useDeleteEnvironment from '../../../queries/enviornment/useDeleteEnvironment';
import SwitchEnvironmentDialog from './SwitchEnvironmentDialog';
import environmentService from '../../../services/environment.service';
import { customerIdSelector } from '../../../selectors/customer';
import { PORTAL_ROUTE } from '../../../constants/routes';
import EnvironmentFormDialog from './EnvironmentFormDialog';
import DeviceLoggingApiKeyDialog from './DeviceLogginApiKeyDialog';
import ConfirmDeviceKeyGenerationDialog from './ConfirmDeviceKeyGenerationDialog';
import useRegenerateKey from '../../../queries/enviornment/useRegenerateKey';
import EnvironmentIdService from '../../../services/environment-id.service';
import useUpdateEnvironments from '../../../queries/enviornment/useUpdateEnvironments';
import useCreateEnvironment from '../../../queries/enviornment/useCreateEnvironment';
import useFetchEnvironments from '../../../queries/useFetchEnvironments';
import useSubscribeStorage from 'src/queries/useSubscribeStorage';
import { DEFAULT_ENVIRONMENT_ID } from 'src/constants/storage';

export const modes = {
  initial: 'initial',
  add: 'add',
  edit: 'edit',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  lineHeight: '24px',
  color: '#fff',
  marginBottom: theme.spacing(2),
}));

const Environments = () => {
  const [mode, setMode] = useState(modes.initial);
  const [activeEnvironmentId, setActiveEnvironmentId] = useState(null);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);
  const [deviceLoggingApiKey, setDeviceLoggingApiKey] = useState('');
  const regnerateKeyMutation = useRegenerateKey();
  const updateEnvironmentMutation = useUpdateEnvironments({
    mode,
  });
  const createEnvironmentMutation = useCreateEnvironment();
  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const limit = Number(searchParams.get('limit')) || 10;
  const { refetch, data: environments, isLoading, lastElementRef, count } = useFetchEnvironments();

  const {
    open: openEnvironmentFormDialog,
    onOpen: onOpenEnvironmentFormDialog,
    onClose: onCloseEnvironmentFormDialog,
  } = useDialog();

  const {
    open: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDialog();

  const {
    open: isSwitchDialogOpen,
    onOpen: onSwitchDialogOpen,
    onClose: onSwitchDialogClose,
  } = useDialog();
  const {
    open: isDeviceLoggingApiKeyDialogOpen,
    onOpen: onOpenDeviceLoggingApiKeyDialog,
    onClose: onCloseDeviceLoggingApiKeyDialog,
  } = useDialog();
  const {
    open: isConfirmDeviceKeyGenerationDialogOpen,
    onOpen: onOpenConfirmDeviceKeyGenerationDialog,
    onClose: onCloseConfirmDeviceKeyGenerationDialog,
  } = useDialog();

  const onEditEnvironment = useCallback(
    selectedEnvironmentId => {
      setMode(modes.edit);
      setSelectedEnvironmentId(selectedEnvironmentId);
      onOpenEnvironmentFormDialog();
    },
    [onOpenEnvironmentFormDialog],
  );

  const onDeleteEnvironmentClick = useCallback(
    selectedEnvironmentId => {
      setSelectedEnvironmentId(selectedEnvironmentId);
      onDeleteDialogOpen();
    },
    [onDeleteDialogOpen],
  );

  const customerEnvId = useSubscribeStorage(DEFAULT_ENVIRONMENT_ID);
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  const deleteEnvironment = useDeleteEnvironment(refetch);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const selectedEnvironment = useMemo(() => {
    if (!environments || !environments?.length || mode === modes.add) return {};

    return environments?.find(env => env.id === selectedEnvironmentId);
  }, [environments, selectedEnvironmentId, mode]);

  const activeEnvironmentName = useMemo(() => {
    if (!activeEnvironmentId || !environments || !environments?.length) return '';

    const activeEnvironment = environments?.find(env => env.id === activeEnvironmentId);
    if (!activeEnvironment) return '';

    return activeEnvironment.name;
  }, [activeEnvironmentId, environments]);

  const onAddNewEnvironment = useCallback(() => {
    setMode(modes.add);
    onOpenEnvironmentFormDialog();
  }, [onOpenEnvironmentFormDialog]);

  const onCancel = useCallback(() => {
    setMode(modes.initial);
  }, [setMode]);

  const onDelete = useCallback(() => {
    setMode(modes.initial);
    deleteEnvironment.mutate({ id: selectedEnvironmentId, force: true });
    onDeleteDialogClose();
  }, [setMode, deleteEnvironment, selectedEnvironmentId, onDeleteDialogClose]);

  const onSwitchEnvironment = useCallback(() => {
    queryClient.setQueryData(USER_QUERY_KEY, prevProps => ({
      ...prevProps,
      defaultCustomerEnvId: activeEnvironmentId,
    }));
    EnvironmentIdService.setId(activeEnvironmentId);
    onSwitchDialogClose();
    environmentService
      .changeActiveEnvironment({ customerEnvId: activeEnvironmentId })
      .then(async () => {
        queryClient.invalidateQueries([APPLICATIONS_QUERY_KEY, '']);
        queryClient.invalidateQueries([DEVICES_QUERY_KEY, '']);
        navigate(`${PORTAL_ROUTE}/devices-dashboard`);
      })
      .catch(() => {
        enqueueSnackbar(pagesText.somethingWentWrong, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, onSwitchDialogClose, queryClient, activeEnvironmentId]);

  const onSelectEnvironment = useCallback(
    environmentId => {
      setActiveEnvironmentId(environmentId);
      onSwitchDialogOpen();
    },
    [onSwitchDialogOpen],
  );

  const regenerateKey = useCallback(() => {
    regnerateKeyMutation.mutate(
      { activeEnvironmentId },
      {
        onSuccess: newKey => {
          if (!activeEnvironmentId) return;

          enqueueSnackbar(pagesText.environments.deviceLoggingApiKey.regenerateSuccess, {
            variant: 'success',
            autoHideDuration: 3000,
          });
          onCloseConfirmDeviceKeyGenerationDialog();
          queryClient.invalidateQueries([ENVIRONEMNTS_QUERY_KEY, customerId]);
          setDeviceLoggingApiKey(newKey);
        },
        onError: () => {
          enqueueSnackbar(pagesText.somethingWentWrong, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          onCloseConfirmDeviceKeyGenerationDialog();
          onCloseDeviceLoggingApiKeyDialog();
        },
      },
    );
  }, [
    customerId,
    regnerateKeyMutation,
    activeEnvironmentId,
    enqueueSnackbar,
    onCloseConfirmDeviceKeyGenerationDialog,
    onCloseDeviceLoggingApiKeyDialog,
    queryClient,
  ]);

  const onLoggingApiKeyClick = useCallback(
    ({ deviceLoggingApiKey, activeEnvironmentId }) => {
      onOpenDeviceLoggingApiKeyDialog();
      setDeviceLoggingApiKey(deviceLoggingApiKey);
      setActiveEnvironmentId(activeEnvironmentId);
    },
    [onOpenDeviceLoggingApiKeyDialog],
  );

  const onDeviceLoggingApiKeySuccess = useCallback(() => {
    if (deviceLoggingApiKey) {
      onOpenConfirmDeviceKeyGenerationDialog();
      return;
    }

    regenerateKey();
  }, [deviceLoggingApiKey, onOpenConfirmDeviceKeyGenerationDialog, regenerateKey]);

  const onAddEnvironment = useCallback(
    (values, options) => {
      createEnvironmentMutation.mutate(values, {
        ...options,
        onSuccess: newData => {
          if (options.onSuccess) {
            options.onSuccess(newData);
          }

          onCloseEnvironmentFormDialog();
          queryClient.invalidateQueries([ENVIRONEMNTS_QUERY_KEY, customerId]);
          enqueueSnackbar('Environment created successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, page, limit],
  );

  const onUpdateEnvironment = useCallback((values, options) => {
    updateEnvironmentMutation.mutate(values, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CompanySectionContainer>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <StyledTypography>
            {pagesText.environments.title} {count ? `(${count})` : ''}
          </StyledTypography>
          <Button
            variant="outlined"
            disabled={isLoading || !customerId}
            size="medium"
            onClick={onAddNewEnvironment}>
            {pagesText.environments.addButton}
          </Button>
        </Stack>
        <EnvironmentsList
          ref={lastElementRef}
          isLoading={isLoading || deleteEnvironment.isLoading}
          environments={environments}
          onSelectEnvironment={onSelectEnvironment}
          selectedEnvId={customerEnvId}
          page={page}
          rowsPerPage={limit}
          onEditEnvironment={onEditEnvironment}
          onDeleteEnvironment={onDeleteEnvironmentClick}
          hasCustomer={!!customerId}
          onLoggingApiKeyClick={onLoggingApiKeyClick}
        />
      </CompanySectionContainer>
      <DeleteEnvironmentDialog
        open={isDeleteDialogOpen}
        onClose={onDeleteDialogClose}
        onSuccess={onDelete}
      />
      <SwitchEnvironmentDialog
        open={isSwitchDialogOpen}
        onClose={onSwitchDialogClose}
        onSuccess={onSwitchEnvironment}
        environmentName={activeEnvironmentName}
      />
      <EnvironmentFormDialog
        open={openEnvironmentFormDialog}
        onClose={onCloseEnvironmentFormDialog}
        title={
          mode === modes.add
            ? pagesText.environments.formDialog.addTitle
            : pagesText.environments.formDialog.editTitle
        }
        onSuccess={mode === modes.add ? onAddEnvironment : onUpdateEnvironment}
        onExitAddMode={onCancel}
        selectedEnvironment={selectedEnvironment}
        mode={mode}
        editEnvironmentId={selectedEnvironmentId}
      />
      <DeviceLoggingApiKeyDialog
        isOpen={isDeviceLoggingApiKeyDialogOpen}
        onClose={onCloseDeviceLoggingApiKeyDialog}
        onSuccess={onDeviceLoggingApiKeySuccess}
        apiKey={deviceLoggingApiKey}
      />
      <ConfirmDeviceKeyGenerationDialog
        isOpen={isConfirmDeviceKeyGenerationDialogOpen}
        onClose={onCloseConfirmDeviceKeyGenerationDialog}
        onSuccess={regenerateKey}
      />
    </>
  );
};

export default Environments;
